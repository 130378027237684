import Fee from "../entities/Fee"

export default {

  name: 'FeeTemplate',

  feeTemplate: {

    refNr: 0,
    name: '',
    defaultTemplate: true,
    fee: Fee.fee,
    active: true,
  }
}