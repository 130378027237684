<template>
  <div v-if="isAuthenticated && organization">
    <div v-if="organization.financeModuleActive">
      <div class="grid-x grid-padding-x">
        <div class="cell callout success"><h3><img width="38px" src="../assets/icons/fee-template.png" alt="Gebührenvorlagen"/>&nbsp;&nbsp;&nbsp;Gebührenvorlagen
        </h3></div>
      </div>
      <div v-if="organization && organization.facilities && organization.facilities.length <= 0"
           class="grid-x grid-padding-x grid-padding-y">
        <NoFacilityCard functionTitle="Gebührenvorlagen"></NoFacilityCard>
      </div>
      <div v-else class="grid-x grid-padding-x grid-padding-y">
        <div class="cell large-12">
          <div>
            <md-table id="fee-template-table-id" v-model="searched" md-sort="name" md-sort-order="asc" md-card md-fixed-header
                      @md-selected="onSelect" style="height: 618px">
              <md-table-toolbar>
                <div class="md-toolbar-section-start grid-x grid-padding-x" style="padding-top: 0.5rem;">
                  <div class="cell large-3 hide-for-medium-only hide-for-small-only">
                    <p class="md-title ai-number">{{searched.length}} Gebührenvorlage<span v-if="searched.length !== 1">n</span></p>
                  </div>
                  <div class="cell large-4 medium-6 small-12">
                    <md-field v-if="availableFacilities && availableFacilities.length > 0">
                      <label>Einrichtung</label>
                      <md-select v-model="selectedFacilityReferenceNumber"
                                 :disabled="sending || availableFacilities.length <= 1"
                                 @md-selected="onSelectFacility">
                        <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                                   :key="facility.referenceNumber">
                          {{ facility.longName }}
                        </md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div class="cell large-1 medium-1 hide-for-small-only">
                    <div v-if="sending">
                      <vue-simple-spinner></vue-simple-spinner>
                    </div>
                  </div>
                  <div class="cell large-4 medium-5 small-12">
                    <md-field md-clearable class="md-toolbar-section-end">
                      <md-input placeholder="Suche nach Name der Vorlage" v-model="search"
                                @input="searchOnTable"/>
                    </md-field>
                  </div>

                  <div class="cell" style="padding-bottom: 15px;">
                    <div class="grid-x grid-padding-x">
                      <div class="cell medium-7 large-8 hide-for-small-only">
                      </div>
                      <div v-if="canWrite" class="cell medium-5 large-4">
                        <md-button @click="onAddFeeTemplate" :disabled="sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite"
                                   class="md-icon-button md-raised md-primary">
                          <md-icon>add</md-icon>
                          <md-tooltip>Neue Gebührenvorlage hinzufügen</md-tooltip>
                        </md-button>
                        <md-button @click="onEditFeeTemplate" :disabled="!selected || sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite"
                                   class="md-icon-button md-raised md-third">
                          <md-icon>edit</md-icon>
                          <md-tooltip>Gebührenvorlage bearbeiten</md-tooltip>
                        </md-button>
                        <md-button @click="onDeleteFeeTemplate" :disabled="!selected || sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite"
                                   class="md-icon-button md-raised md-accent">
                          <md-icon>delete</md-icon>
                          <md-tooltip>Gebührenvorlage löschen</md-tooltip>
                        </md-button>
                      </div>
                      <div v-else class="cell medium-5 large-4">
                        <md-button @click="onViewFeeTemplate" :disabled="!selected || sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement)"
                                   class="md-icon-button md-raised md-third">
                          <md-icon>visibility</md-icon>
                          <md-tooltip>Vertrag einsehen</md-tooltip>
                        </md-button>
                      </div>
                    </div>
                  </div>

                </div>
              </md-table-toolbar>

              <md-table-empty-state v-if="search"
                                    md-label="Keine Gebührenvorlage gefunden"
                                    :md-description="`Keine Gebührenvorlage mit dem Suchbegriff '${search}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
              </md-table-empty-state>

              <md-table-row :id="item.refNr" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
                <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}
                </md-table-cell>
                <md-table-cell md-label="Gebührenart" md-sort-by="fee.feeType">{{ labels.feeType[item.fee.feeType] }}
                </md-table-cell>
                <md-table-cell md-label="Zahlungsintervall" md-sort-by="fee.interval">{{ labels.paymentInterval[item.fee.interval] }}
                </md-table-cell>
                <md-table-cell md-label="Preisliste" md-sort-by="fee.pricelist.name">
                  <span v-if="item.fee.pricelist">{{ item.fee.pricelist.name }}</span>
                </md-table-cell>
                <md-table-cell md-label="Basisbetrag" md-sort-by="fee.baseAmount">{{ item.fee.baseAmount | currency }}
                </md-table-cell>
                <md-table-cell md-label="Ermäßigung (%)" md-sort-by="fee.discountPercentage">{{ item.fee.discountPercentage }}%
                </md-table-cell>
                <md-table-cell md-label="Nachlass (€)" md-sort-by="fee.discountAbsolute">{{ item.fee.discountAbsolute | currency }}
                </md-table-cell>
                <md-table-cell md-label="zu bezahlen" md-sort-by="fee.amount">{{ item.fee.amount | currency }}
                </md-table-cell>
                <md-table-cell md-label="steuer-relevant">
                  <span v-if="item.fee.taxRelevant"><md-icon style="color: green">done</md-icon></span>
                </md-table-cell>
              </md-table-row>

            </md-table>
          </div>
        </div>
      </div>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteFeeTemplateDialog">
        <div v-if="selectedFeeTemplate" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <img width="38px" src="../assets/icons/fee-template.png" alt="Gebührenvorlagen"/>&nbsp;&nbsp;&nbsp;Gebührenvorlage löschen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll die Gebührenvorlage <span class="title"><b>{{selectedFeeTemplate.name}}</b></span> der Einrichtung <span
                class="title">{{selectedFacilityName}}</span> wirklich gelöscht werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button @click="deleteFeeTemplate" class="button alert" :disabled="sending"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
              Gebührenvorlage jetzt löschen
            </button>
            <button class="button success" style="margin-left: 1rem;" @click="showDeleteFeeTemplateDialog = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="showDeleteFeeTemplateDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <EditFeeDialog :showDialog="showEditFeeDialog" :selectedFee="selectedFee"
                     :selectedFeeTemplate="selectedFeeTemplate" :mode="dialogMode"
                     :selectedFacilityReferenceNumber="selectedFacilityReferenceNumber"
                     @updateFeeTemplateSuccess="updateFeeTemplateSuccess"
                     @closeEditFeeDialog="showEditFeeDialog = false"></EditFeeDialog>
    </div>
    <div v-else style="padding: 1rem">
      <NoModuleCard moduleType="FINANCE" moduleFunction="Gebührenvorlagen"></NoModuleCard>
    </div>
  </div>
</template>

<script>
  import jQuery from 'jquery';
  import Spinner from 'vue-simple-spinner';
  import FacilityService from '../services/FacilityService';
  import HttpErrorHandler from '../services/HttpErrorHandler';
  import NoFacilityCard from "../components/cards/NoFacilityCard";
  import EditFeeDialog from "../components/dialogs/EditFeeDialog";
  import FeeTemplate from "../entities/FeeTemplate";
  import NoModuleCard from '../components/cards/NoModuleCard';

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByName = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.name).includes(toLower(term)));
    }

    return items;
  };

  export default {
    name: 'FeeTemplates',
    components: {
      'vue-simple-spinner': Spinner,
      NoFacilityCard,
      EditFeeDialog,
      NoModuleCard,
    },

    mounted() {
      this.restoreUserSettings();
      this.reloadFeeTemplates();

      HttpErrorHandler.maintainDarkMode(this);
    },

    data() {
      return {
        sending: false,
        feeTemplates: [],
        searched: [],
        search: null,
        selected: null,
        selectedFee: null,
        selectedFeeTemplate: null,
        showDeleteFeeTemplateDialog: false,
        showEditFeeDialog: false,
        success: false,
        dialogMode: 'update',

        updateAssignedFees: false,

        selectedRowId: 0,

        availableFacilities: [],
        availableFacilitiesMap: {},
        allowedFacilities: [],
        selectedFacilityReferenceNumber: null,
        selectedFacilityName: '',
      }
    },

    methods: {

      restoreUserSettings() {
        this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
        if (this.selectedFacilityReferenceNumber === '*') {
          this.selectedFacilityReferenceNumber = null;
        }
      },

      reloadFeeTemplates() {
        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {

            if (!this.selectedFacilityReferenceNumber) {
              this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
            }
            this.buildAvailableFacilities();

            clearInterval(reloadIntervalId);
            jQuery('.md-content.md-table-content.md-scrollbar').attr('style', 'height: 635px');

          } else {
            this.sending = true;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = false;
              clearInterval(reloadIntervalId);
              HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
            }
          }
        }, 250);
      },

      buildAvailableFacilities() {
        this.availableFacilities = this.getAvailableFacilities;
        for (let i = 0; i < this.availableFacilities.length; i++) {
          if (this.availableFacilities[i].referenceNumber === '*') {
            this.availableFacilities = this.availableFacilities.slice(0, i).concat(this.availableFacilities.slice(i + 1, this.availableFacilities.length));
          }
        }
        if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
          this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
          this.selectedFacilityName = this.availableFacilities[0].name;
        }
        this.allowedFacilities = [];
        for (let facility of this.availableFacilities) {
          if (facility.referenceNumber !== '*') {
            this.allowedFacilities.push(facility);
          }
        }

        this.onSelectFacility();
      },

      onSelectFacility() {
        if (this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

          localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);

          this.selectedFacilityName = '';
          for (let facility of this.availableFacilities) {
            if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
              this.selectedFacilityName = facility.name;
            }
          }

          if (this.selectedFacilityReferenceNumber === '*') {
            this.getFeeTemplates();
          } else {
            this.getFacilityFeeTemplates(this.selectedFacilityReferenceNumber);
          }

          this.searchOnTable();
        }
      },

      getFeeTemplates() {
        if (this.selectedFacilityReferenceNumber) {
          this.getFacilityFeeTemplates(this.selectedFacilityReferenceNumber);
        }
      },

      getFacilityFeeTemplates(referenceNumber) {
        if (!(this.organization && this.organization.facilities && this.organization.facilities.length > 0)) {
          return;
        }
        if (!referenceNumber || referenceNumber === 'null') {
          return;
        }
        if (this.isAdmin || this.isTreasurer) {
          this.sending = true;
          FacilityService.getFeeTemplates(referenceNumber)
            .then(response => {
              this.feeTemplates = response.data;
              this.searchOnTable();
              this.sending = false;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Gebührenvorlagen für Einrichtung mit Nummer ' + referenceNumber);
              this.sending = false;
            })
        }
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      searchOnTable() {
        this.searched = searchByName(this.feeTemplates, this.search);
      },

      onAddFeeTemplate() {

        if (this.selected) {
          jQuery('#' + this.selectedRowId).trigger('click');
        }

        setTimeout(() => {
          this.dialogMode = 'add';
          this.selectedFeeTemplate = JSON.parse(JSON.stringify(FeeTemplate.feeTemplate));
          this.selectedFee = this.selectedFeeTemplate.fee;
          this.showEditFeeDialog = true;
        }, 50);
      },

      onDeleteFeeTemplate() {
        if (this.selected) {
          this.showDeleteFeeTemplateDialog = true;
        }
      },

      deleteFeeTemplate() {
        if (this.selectedFeeTemplate) {
          this.sending = true;
          FacilityService.deleteFeeTemplate(this.selectedFacilityReferenceNumber, this.selectedFeeTemplate.refNr)
            .then(() => {
              this.sending = false;
              this.$store.commit('successMsg', 'Die Gebührenvorlage &nbsp;<b>' + this.selectedFeeTemplate.name + '</b>&nbsp;wurde erfolgreich gelöscht.');

              this.search = null;
              this.selected = null;
              this.selectedFeeTemplate = null;
              this.showDeleteFeeTemplateDialog = false;
              this.reloadFeeTemplates();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Gebührenvorlage ' + this.selectedFeeTemplate.name);
              this.sending = false;
            })
        }
      },

      onEditFeeTemplate() {
        this.dialogMode = 'update';

        if (this.selected) {
          this.selectedFee = this.selectedFeeTemplate.fee;
          this.showEditFeeDialog = true;
        }
      },

      onViewFeeTemplate() {
        this.dialogMode = 'view';

        if (this.selected) {
          this.selectedFee = this.selectedFeeTemplate.fee;
          this.showEditFeeDialog = true;
        }
      },

      closeEditPriceListDialog() {
        this.showEditFeeDialog = false;
      },

      updateFeeTemplateSuccess(newFeeTemplate, facility) {
        if (this.dialogMode === 'add') {
          this.$store.commit('successMsg', 'Die Gebührenvorlage &nbsp;<b>' + newFeeTemplate.name + '</b>&nbsp;wurde erfolgreich angelegt.');

          setTimeout(() => {
            let id = facility.feeTemplates[facility.feeTemplates.length - 1].refNr;
            jQuery('#' + id).trigger('click');
            let list = jQuery('#fee-template-table-id').children().first().next().next();
            list.animate({scrollTop: document.getElementById(id).offsetTop}, 'slow');
          }, 150);

        } else
        if (this.dialogMode === 'update') {
          this.$store.commit('successMsg', 'Die Gebührenvorlage &nbsp;<b>' + newFeeTemplate.name + '</b>&nbsp;wurde erfolgreich geändert.');
        }
        this.feeTemplates = facility.feeTemplates;
        this.search = '';
        this.searchOnTable();
      },

      onSelect(item) {
        if (item && item.refNr >= 0) {
          this.dialogMode = 'update';

          this.selectedRowId = item.refNr;
          this.selected = JSON.parse(JSON.stringify(item));
          this.selectedFeeTemplate = JSON.parse(JSON.stringify(this.selected));
          this.selectedFee = this.selectedFeeTemplate.fee;

        } else {
          this.selected = null;
          this.selectedFeeTemplate = null;
        }
      },

      onSaveChanges() {
        this.updateAssignedFees = false;
      },

      validatePriceList() {
        if (this.dialogMode === 'update') {
          this.$refs.priceListForm.validatePriceList();

          if (!this.$refs.priceListForm.invalid) {
            this.onSaveChanges();
          } else {
            // this.confirmMissingData();
          }
        }
      },
    },

    computed: {
      dataAvailable() {
        return this.organization && this.organization.facilities && this.user && this.user.allowedFacilities &&
            this.user.allowedFacilities.length > 0 && this.labels &&
            this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isFacility() {
        return this.$store.getters.facility;
      },

      isFacilityAdmin() {
        return this.$store.getters.facilityAdmin;
      },

      isTreasurer() {
        return this.$store.getters.treasurer;
      },

      isManagement() {
        return this.$store.getters.management;
      },

      canWrite() {
        return this.$store.getters.canWrite;
      },

      organization() {
        return this.$store.getters.organization;
      },

      user() {
        return this.$store.getters.user;
      },

      labels() {
        return this.$store.getters.labels;
      },

      getAvailableFacilities() {
        return this.$store.getters.availableFacilities;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .md-dialog /deep/.md-dialog-container {
    max-width: 900px;
  }

  h5 {
    font-weight: 400;
    color: lightseagreen;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  .title {
    color: chocolate;
    font-weight: bold;
    font-size: larger;
  }

  .title-thin {
    color: cornflowerblue;
    font-weight: lighter;
    font-size: larger;
  }

  .md-tooltip {
    font-size: medium;
    white-space: normal;
  }

  .md-tooltip-block {
    font-size: medium;
    height: auto;
    max-width: 375px;
    white-space: pre-wrap;
  }
</style>
